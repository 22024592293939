import React from 'react';
import './QuranicWords.css';

const wordsData = [
  {
    word: 'Rahma',
    arabicWord: 'رحمة',
    translation: 'Mercy',
    explanation: 'A comprehensive understanding of divine mercy and its significance.',
    quranVerse: 'فَإِنَّ اللَّهَ غَفُورٌ رَّحِيمٌ',
    verseTranslation: 'Indeed, Allah is Forgiving and Merciful.',
    verseReference: 'Surah Al-Baqarah (2:218)',
    story: "The story of Prophet Muhammad (PBUH) during the conquest of Makkah exemplifies divine mercy (Rahma). After years of hardship, persecution, and exile by the people of Makkah, the Prophet (PBUH) and his followers returned to reclaim Makkah. Despite being wronged and persecuted by the Makkans, the Prophet (PBUH) forgave all those who had opposed him, showing immense mercy. He declared that there would be no retribution for their past actions, and they were granted forgiveness. This act of mercy reflected the core attribute of Allah's Rahma, as the Prophet (PBUH) was sent as a mercy to the worlds (Rahmatan lil-Alamin). The conquest of Makkah was not only a military victory but also a triumph of mercy, as many enemies of Islam embraced the faith.",
  },
  {
    word: 'Sabr',
    arabicWord: 'صبر',
    translation: 'Patience',
    explanation: 'Explanation of patience and its rewards in various aspects of life.',
    quranVerse: 'إِنَّ اللّهَ مَعَ الصَّابِرِينَ',
    verseTranslation: 'Indeed, Allah is with the patient.',
    verseReference: 'Surah Al-Baqarah (2:153)',
    story: "The story of Prophet Ayyub (Job) is one of the most poignant examples of Sabr (Patience). Ayyub was a prosperous man, blessed with a large family, health, and wealth. However, Allah tested him by taking away his wealth, his children, and his health, leaving him in a state of immense suffering for many years. Despite his hardship, Ayyub never lost faith in Allah. He was patient, never complained, and continued to praise and worship Allah. His famous words, 'Indeed, Allah is with the patient,' reflect his deep reliance on Allah during his trials. Ultimately, his patience was rewarded by Allah, who restored his health, wealth, and family. Ayyub's story serves as a powerful reminder of the virtue of patience and the reward that comes with it in this world and the Hereafter.",
  },
  {
    word: 'Tawakkul',
    arabicWord: 'توكلت',
    translation: 'Reliance on Allah',
    explanation: 'The concept of relying on Allah after making an effort, trusting that He will guide us.',
    quranVerse: 'وَعَلَىٰ اللَّهِ فَتَوَكَّلْتُ',
    verseTranslation: 'And upon Allah, I have relied.',
    verseReference: 'Surah Al-Imran (3:159)',
    story: "Prophet Muhammad (PBUH) and his companions demonstrated Tawakkul (reliance on Allah) during their migration (Hijrah) from Makkah to Medina. Despite being pursued by enemies who sought to harm them, the Prophet (PBUH) placed his complete trust in Allah. When the enemies were close to capturing them while they were hiding in the cave of Thawr, the Prophet's companion, Abu Bakr (RA), feared for their safety. However, the Prophet (PBUH) reassured him, saying: 'What do you think of two, the third of whom is Allah?' This profound trust in Allah's protection exemplified Tawakkul, as they continued their journey, relying fully on Allah's will. Allah guided them safely to Medina, and their trust was rewarded. Their example teaches us to make efforts while placing trust in Allah's plan.",
  },
  {
    word: 'Shukr',
    arabicWord: 'شكر',
    translation: 'Gratitude',
    explanation: 'The importance of being thankful to Allah for His countless blessings.',
    quranVerse: 'لَئِن شَكَرْتُمْ لَأَزِيدَنَّكُمْ',
    verseTranslation: 'If you are grateful, I will certainly increase your favor upon you.',
    verseReference: 'Surah Ibrahim (14:7)',
    story: "Prophet Dawood (David) was a model of gratitude, known for his thankfulness to Allah. Despite his kingship, wealth, and power, Prophet Dawood (PBUH) always acknowledged Allah as the source of his blessings. One of the notable stories of his gratitude is his constant reflection on the blessings of Allah. Prophet Dawood (PBUH) would often praise Allah, using his voice to sing songs of thanks to Allah, and he was given the ability to speak with a beautiful voice that resonated with the heavens. Once, Allah told him that if he was grateful for His blessings, Allah would increase them. Prophet Dawood’s gratefulness was evident in his devotion and the way he ruled justly, ensuring the welfare of his people. Allah rewarded his Shukr by giving him further wisdom and blessings, demonstrating that true gratitude is a means of attracting Allah’s mercy and favor.",
  },
  {
    word: 'Hikmah',
    arabicWord: 'حكمة',
    translation: 'Wisdom',
    explanation: 'The ability to make sound decisions based on knowledge and experience, guided by divine wisdom.',
    quranVerse: 'يُؤْتِي الْحِكْمَةَ مَن يَشَاءُ',
    verseTranslation: 'He gives wisdom to whom He wills.',
    verseReference: 'Surah Al-Baqarah (2:269)',
    story: "Prophet Sulaiman (Solomon) is a remarkable figure in Islamic tradition, renowned for his divine gift of Hikmah (Wisdom). One of the most famous stories of his wisdom occurred when two women came to him, each claiming to be the mother of a baby. Prophet Sulaiman, using his divine wisdom, proposed to divide the child in half and give each woman a part. The true mother immediately offered to give up her claim to save the child, revealing her identity. Prophet Sulaiman's wisdom allowed him to make the right decision, demonstrating his ability to judge with fairness and understanding. His wisdom extended beyond human affairs, as he was also gifted with the ability to communicate with animals and control the wind, using his knowledge to govern justly and in accordance with Allah’s will.",
  },
  {
    word: 'Barakah',
    arabicWord: 'بركة',
    translation: 'Blessing',
    explanation: 'Divine blessings that bring goodness, success, and prosperity in one\'s life.',
    quranVerse: 'وَيُرْسِلِ ٱلرِّياحَ لِيُذِيقَكُمۡ بَعۡضَ ٱلَّذِىٓ ءَاتَاكُمۡ',
    verseTranslation: 'And He sends the winds as a glad tiding before His mercy.',
    verseReference: 'Surah Ar-Rum (30:48)',
    story: "One of the most famous stories of Barakah (blessing) in the life of Prophet Muhammad (PBUH) occurred during a significant gathering where food was scarce. On one occasion, the Prophet (PBUH) and his companions were faced with a severe shortage of food. A small amount of food had been brought to him, and there were many people who needed to be fed. The Prophet (PBUH), with his immense trust in Allah, prayed for Barakah in the food. He asked Allah to bless it and then distributed it among the people. As the companions began serving the food, a miraculous event unfolded: despite the meager initial quantity, the food never seemed to diminish, and every person present ate to their fill. The small provision not only sufficed for the large gathering but also left leftovers, displaying the extraordinary blessing (Barakah) that came from the Prophet's prayer. This miraculous event exemplified the concept of Barakah, where Allah’s blessings can make something seemingly small or insufficient yield abundance and prosperity. It is a powerful reminder of how sincere prayers and divine blessings can bring unimaginable goodness and prosperity, even in seemingly impossible situations.",
  },
  {
    word: 'Najat',
    arabicWord: 'نجاة',
    translation: 'Salvation',
    explanation: 'The ultimate safety and deliverance from harm and sin by Allah.',
    quranVerse: 'إِنَّ الَّذِينَ آمَنُوا وَعَمِلُوا الصَّالِحَاتِ فَأُو۟لَـٰٓئِكَ أَصۡحَابُ ٱلۡجَنَّةِ',
    verseTranslation: 'Indeed, those who have believed and done righteous deeds - they are the companions of Paradise.',
    verseReference: 'Surah Al-Baqarah (2:82)',
    story: 'The story of Najat (Salvation) is vividly illustrated in the life of Prophet Nuh (Noah), who was one of the earliest prophets sent to guide humanity. For hundreds of years, Prophet Nuh tirelessly called his people to worship Allah alone and abandon their idol worship. He faced immense resistance, mockery, and opposition, as many of his people refused to listen and persisted in their disbelief and sinful ways.\n\nDespite the challenges, Prophet Nuh remained steadfast and continued his mission with patience and perseverance. Eventually, when it became clear that the majority of his people would not change their ways, Allah revealed to Nuh that a great flood would be sent as a punishment for the disbelievers and a means of salvation for the believers. Nuh was instructed by Allah to build an ark, a task that he carried out diligently, despite the ridicule and scorn from his people.\n\nAs the ark was completed, Allah commanded Nuh to gather pairs of every animal species, along with the small group of believers who had heeded his message. When the floodwaters began to surge from the earth and rain poured from the sky, it became evident that the warning was real. The disbelievers were overwhelmed by the rising waters, and only those who had sought Najat through their faith and righteous deeds were saved aboard the ark. The flood wiped out the disbelievers, but Prophet Nuh and his followers were delivered to safety, a profound example of divine salvation.\n\nThis story emphasizes the importance of faith, righteousness, and perseverance. It serves as a reminder that true Najat comes from belief in Allah, obedience to His commands, and unwavering trust in His wisdom and protection. Those who place their hope and reliance in Allah will find salvation, even in the most dire circumstances.'
  },
  {
    word: 'Falah',
    arabicWord: 'فلاح',
    translation: 'Success',
    explanation: 'True success, both in this life and the hereafter, which is attained through faith and good deeds.',
    quranVerse: 'قَدْ أَفْلَحَ مَن تَزَكَّىٰ',
    verseTranslation: 'He has succeeded who purifies himself.',
    verseReference: 'Surah Al-Ala (87:14)',
    story: 'The story of the Prophet Muhammad (PBUH) emphasizing the significance of purification of the soul for achieving success.',
  },
  {
    word: 'Ilm',
    arabicWord: 'علم',
    translation: 'Knowledge',
    explanation: 'The importance of acquiring knowledge and its role in elevating one’s understanding and faith.',
    quranVerse: 'وَقُل رَّبِّ زِدْنِي عِلْمًا',
    verseTranslation: 'And say, "My Lord, increase me in knowledge."',
    verseReference: 'Surah Taha (20:114)',
    story: 'The story of how Allah granted Prophet Musa (Moses) great knowledge, guiding him to successfully confront Pharaoh.',
  },
  {
    word: 'Khalifah',
    arabicWord: 'خليفة',
    translation: 'Successor',
    explanation: 'The concept of being a caretaker and representative of Allah on earth.',
    quranVerse: 'إِنِّي جَاعِلٌ فِي ٱلۡأَرۡضِ خَلِيفَةً',
    verseTranslation: 'Indeed, I am going to make upon the earth a successive authority.',
    verseReference: 'Surah Al-Baqarah (2:30)',
    story: 'The story of Prophet Adam, who was made the first Khalifah to steward the earth.',
  },
  {
    word: 'Salam',
    arabicWord: 'سلام',
    translation: 'Peace',
    explanation: 'A greeting of peace, and the state of tranquility and security bestowed by Allah.',
    quranVerse: 'فَأَفِيٓئُوا۟ إِلَىٰ سَلاَمٍ',
    verseTranslation: 'So return to peace.',
    verseReference: 'Surah Al-Baqarah (2:208)',
    story: 'The story of how Prophet Isa (Jesus) brought peace and healing to his people through his message.',
  },
  {
    word: 'Zakat',
    arabicWord: 'زكاة',
    translation: 'Charity',
    explanation: 'The obligation to give part of one’s wealth to the needy, a purifying act.',
    quranVerse: 'وَأَقِيمُوا۟ ٱلصَّلَوٰةَ وَءَاتُوا۟ ٱلزَّكَاةَ',
    verseTranslation: 'And establish the prayer and give the zakat.',
    verseReference: 'Surah Al-Baqarah (2:43)',
    story: 'The story of how the Prophet Muhammad (PBUH) encouraged charity among the early Muslims, establishing the significance of zakat.',
  },
  {
    word: 'Jannah',
    arabicWord: 'جنة',
    translation: 'Paradise',
    explanation: 'The eternal, blissful abode promised to the righteous in the Hereafter.',
    quranVerse: 'إِنَّ ٱلَّذِينَ آمَنُوا۟ وَعَمِلُوا۟ ٱلصَّـٰلِحَٰتِ فِي جَنَّـٰتِ ٱلنَّعِيمِ',
    verseTranslation: 'Indeed, those who have believed and done righteous deeds will be in gardens of pleasure.',
    verseReference: 'Surah Al-Baqarah (2:82)',
    story: 'The story of the Prophet Muhammad (PBUH) describing the beauty and blessings of Jannah to his companions.',
  },
  {
    word: 'Shahada',
    arabicWord: 'شهادة',
    translation: 'Testimony',
    explanation: 'The declaration of faith that there is no god but Allah, and Muhammad is His messenger.',
    quranVerse: 'مَّحَمَّدٌۭ رَّسُولُ ٱللَّهِ',
    verseTranslation: 'Muhammad is the messenger of Allah.',
    verseReference: 'Surah Al-Fath (48:29)',
    story: 'The story of the first declaration of the Shahada by the Prophet Muhammad (PBUH) and the early Muslims.',
  },
  {
    word: 'Ruqyah',
    arabicWord: 'رقية',
    translation: 'Spiritual Healing',
    explanation: 'The practice of using Quranic verses for healing, protection, and seeking blessings.',
    quranVerse: 'وَنُنَزِّلُ مِنَ ٱلۡقُرْآنِ مَا هُوَ شِفَاءٌ وَرَحْمَةٌ لّّۤلِّمُؤْمِنِينَ',
    verseTranslation: 'And We send down in the Qur\'an that which is healing and mercy for the believers.',
    verseReference: 'Surah Al-Isra (17:82)',
    story: 'The story of how the Prophet Muhammad (PBUH) used the Quranic verses for healing purposes and advised his companions to use them.',
  },
  {
    word: 'Mu’min',
    arabicWord: 'مؤمن',
    translation: 'Believer',
    explanation: 'A person who believes in the unseen and submits to Allah\'s commands.',
    quranVerse: 'إِنَّمَا ٱلْمُؤْمِنُونَ إِخْوَةٌ',
    verseTranslation: 'The believers are but brothers.',
    verseReference: 'Surah Al-Hujurat (49:10)',
    story: 'The story of how the early Muslims, despite facing hardships, supported one another, exemplifying true brotherhood among the Mu’min.',
  },
  {
    word: 'Ikhlas',
    arabicWord: 'إخلاص',
    translation: 'Sincerity',
    explanation: 'The act of doing good deeds purely for the sake of Allah.',
    quranVerse: 'قُلْ هُوَ ٱللَّهُ أَحَدٌ',
    verseTranslation: 'Say, "He is Allah, [Who is] One.',
    verseReference: 'Surah Al-Ikhlas (112:1)',
    story: 'The revelation of Surah Al-Ikhlas to the Prophet Muhammad (PBUH), emphasizing the oneness of Allah.',
  },
  // Add more words as needed
  {
    word: 'Iman',
    arabicWord: 'إيمان',
    translation: 'Faith',
    explanation: 'Belief in the unseen and trust in Allah and His commandments.',
    quranVerse: 'إِنَّمَا ٱلۡمُؤْمِنُونَ الَّذِينَ آمَنُوا۟ بِاللَّهِ وَرَسُولِهِۥ',
    verseTranslation: 'The believers are only those who have believed in Allah and His Messenger.',
    verseReference: 'Surah Al-Hujurat (49:15)',
    story: 'The story of the companions who demonstrated complete Iman during the Battle of Uhud.',
  },
  {
    word: 'Tawbah',
    arabicWord: 'توبة',
    translation: 'Repentance',
    explanation: 'Seeking forgiveness from Allah for past sins and returning to Him.',
    quranVerse: 'إِنَّ ٱللَّهَ يُحِبُّ ٱلتَّوَّابِينَ',
    verseTranslation: 'Indeed, Allah loves those who repent.',
    verseReference: 'Surah Al-Baqarah (2:222)',
    story: 'The story of how the Prophet Muhammad (PBUH) taught his companions the importance of repentance.',
  },
  {
    word: 'Noor',
    arabicWord: 'نور',
    translation: 'Light',
    explanation: 'The divine light of guidance and knowledge that illuminates the hearts of believers.',
    quranVerse: 'ٱللَّهُ وَلِيُّ ٱلَّذِينَ ءَامَنُوا۟ يُخْرِجُهُمۡ مِنَ ٱلظُّلُمَٰتِ إِلَىٰ ٱلنُّورِ',
    verseTranslation: 'Allah is the protector of those who believe. He brings them out from darkness into light.',
    verseReference: 'Surah Al-Baqarah (2:257)',
    story: 'The story of how the companions were guided by the Noor of the Quran.',
  },
  {
    word: 'Fajr',
    arabicWord: 'فجر',
    translation: 'Dawn',
    explanation: 'The time of the early morning prayer, marking the beginning of the day.',
    quranVerse: 'وَفَجَّرْنَا ٱلۡأَرۡضَ عُيُونًا',
    verseTranslation: 'And We caused the earth to gush forth with springs.',
    verseReference: 'Surah Al-Qamar (54:12)',
    story: 'The story of how the Prophet Muhammad (PBUH) would start his day with the Fajr prayer.',
  },
  {
    word: 'Maghfirah',
    arabicWord: 'مغفرة',
    translation: 'Forgiveness',
    explanation: 'The act of Allah forgiving sins and pardoning mistakes.',
    quranVerse: 'وَٱللَّهُ غَفُورٌۭ رَّحِيمٌ',
    verseTranslation: 'And Allah is Forgiving and Merciful.',
    verseReference: 'Surah Al-Mujadila (58:2)',
    story: 'The story of how the Prophet Muhammad (PBUH) sought forgiveness for his people.',
  },
  {
    word: 'Salah',
    arabicWord: 'صلاة',
    translation: 'Prayer',
    explanation: 'The five daily prayers that connect Muslims to Allah.',
    quranVerse: 'وَأَقِيمُوا۟ ٱلصَّلَوٰةَ',
    verseTranslation: 'And establish the prayer.',
    verseReference: 'Surah Al-Baqarah (2:43)',
    story: 'The story of the importance of Salah in the life of the Prophet Muhammad (PBUH).',
  },
  {
    word: 'Dhikr',
    arabicWord: 'ذكر',
    translation: 'Remembrance',
    explanation: 'Recitation of Allah\'s name to maintain mindfulness of Him.',
    quranVerse: 'فَٱذۡكُرُونِىٓ أَذۡكُرْكُمْ',
    verseTranslation: 'So remember Me; I will remember you.',
    verseReference: 'Surah Al-Baqarah (2:152)',
    story: 'The story of the Prophet Muhammad (PBUH) teaching his companions the importance of Dhikr in their daily lives.',
  },
  {
    word: 'Amanah',
    arabicWord: 'أمانة',
    translation: 'Trust',
    explanation: 'The responsibility and trust placed by Allah on His creation.',
    quranVerse: 'إِنَّ ٱللَّهَ يَأْمُرُكُمُ أَن تُؤَدُّوا۟ ٱلْأَمَٰنَٰتِ إِلَىٰٓ أَهْلِهَا',
    verseTranslation: 'Indeed, Allah commands you to render trusts to whom they are due.',
    verseReference: 'Surah An-Nisa (4:58)',
    story: 'The story of how the Prophet Muhammad (PBUH) was known for his Amanah, even before his prophethood.',
  },
  {
    word: 'Adl',
    arabicWord: 'عدل',
    translation: 'Justice',
    explanation: 'Fairness in dealing with others, upholding justice in all matters.',
    quranVerse: 'إِنَّ ٱللَّهَ يَأْمُرُ بِٱلۡعَدْلِ',
    verseTranslation: 'Indeed, Allah commands justice.',
    verseReference: 'Surah An-Nahl (16:90)',
    story: 'The story of the Prophet Muhammad (PBUH) ensuring justice for everyone, regardless of status.',
  },
  {
    word: 'Salamun Alaikum',
    arabicWord: 'سلامٌ عليكم',
    translation: 'Peace be upon you',
    explanation: 'A greeting of peace exchanged between Muslims.',
    quranVerse: 'وَإِذَا حُيِّيتُم بِتَحِيَّةٍ فَحَيُّوا۟ بِأَحْسَنَ مِنْهَآ',
    verseTranslation: 'And when you are greeted with a greeting, greet with something better.',
    verseReference: 'Surah An-Nisa (4:86)',
    story: 'The story of how the greeting "Salamun Alaikum" was introduced to spread peace among Muslims.',
  },
  {
    word: 'Dunya',
    arabicWord: 'دنيا',
    translation: 'Worldly Life',
    explanation: 'The transient, temporal world in which humans live before the Hereafter.',
    quranVerse: 'وَمَا ٱلۡحَيَوٰةُ ٱلدُّنۡيَآ إِلَّا لَعِبٌۭ وَلَهْوٌۭ',
    verseTranslation: 'And the life of this world is but play and diversion.',
    verseReference: 'Surah Al-Ankabut (29:64)',
    story: 'The story of the Prophet Muhammad (PBUH) reminding his companions about the impermanence of Dunya.',
  },
  {
    word: 'Akhirah',
    arabicWord: 'آخرة',
    translation: 'The Hereafter',
    explanation: 'The eternal life after death, where everyone will be judged by Allah.',
    quranVerse: 'إِنَّمَا تُوَفَّوْنَ أُجُورَكُمْ يَوْمَ الْقِيَامَةِ',
    verseTranslation: 'You will only be fully compensated on the Day of Judgment.',
    verseReference: 'Surah Al-Imran (3:185)',
    story: 'The story of the Prophet Muhammad (PBUH) emphasizing the importance of preparing for the Akhirah.',
  },
  {
    word: 'Jihad',
    arabicWord: 'جهاد',
    translation: 'Struggle',
    explanation: 'The struggle in the way of Allah, both spiritually and physically.',
    quranVerse: 'فَإِنَّ ٱللَّهَ مَعَ ٱلَّذِينَ ٱتَّقَوْا۟',
    verseTranslation: 'Indeed, Allah is with those who fear Him.',
    verseReference: 'Surah At-Tawbah (9:36)',
    story: 'The story of the early battles fought by the Prophet Muhammad (PBUH) to protect Islam.',
  },
  {
    word: 'Qamar',
    arabicWord: 'قمر',
    translation: 'Moon',
    explanation: 'A celestial body mentioned in the Quran, symbolizing beauty and divine creation.',
    quranVerse: 'وَٱلۡقَمَرَ قَدَّرْنَٰهُ مَنَازِلَ',
    verseTranslation: 'And the moon We have determined for its phases.',
    verseReference: 'Surah Ya-Sin (36:39)',
    story: 'The story of the moon splitting into two during the time of Prophet Muhammad (PBUH) as a miracle.',
  },
  {
    word: 'Zulm',
    arabicWord: 'ظلم',
    translation: 'Oppression',
    explanation: 'The act of injustice, oppression, or wrong-doing.',
    quranVerse: 'إِنَّ ٱللَّهَ لَا يُحِبُّ ٱلۡمُعْتَدِينَ',
    verseTranslation: 'Indeed, Allah does not like the wrongdoers.',
    verseReference: 'Surah Al-Baqarah (2:190)',
    story: 'The story of how Allah condemns all forms of Zulm and commands justice and fairness.',
  },
  {
    word: 'Shukr',
    arabicWord: 'شكر',
    translation: 'Gratitude',
    explanation: 'Thankfulness and appreciation for Allah’s blessings.',
    quranVerse: 'لَئِن شَكَرْتُمْ لَأَزِيدَنَّكُمْ',
    verseTranslation: 'If you are grateful, I will surely increase your favor upon you.',
    verseReference: 'Surah Ibrahim (14:7)',
    story: 'The story of how the Prophet Muhammad (PBUH) would thank Allah for even the smallest blessings.',
  },
  {
    word: 'Barakah',
    arabicWord: 'بركة',
    translation: 'Blessing',
    explanation: 'Divine favor and abundance that extends beyond ordinary measure.',
    quranVerse: 'وَرَحْمَتِى وَسِعَتْ كُلَّ شَىْءٍ',
    verseTranslation: 'And My mercy encompasses all things.',
    verseReference: 'Surah Al-A’raf (7:156)',
    story: 'The story of the Barakah that Allah granted to the early Muslims during difficult times.',
  },
  {
    word: 'Sabr',
    arabicWord: 'صبر',
    translation: 'Patience',
    explanation: 'The ability to remain steadfast and patient in times of trial and difficulty.',
    quranVerse: 'وَٱصۡبِرُوا۟ وَمَا صَبۡرُكُمٓ إِلَّا بِٱللَّهِ',
    verseTranslation: 'And be patient, for your patience is with the help of Allah.',
    verseReference: 'Surah An-Nahl (16:127)',
    story: 'The story of how the Prophet Muhammad (PBUH) showed immense Sabr during the trials of his life.',
  },
  {
    word: 'Rahma',
    arabicWord: 'رحمة',
    translation: 'Mercy',
    explanation: 'Divine mercy and compassion that Allah shows towards His creation.',
    quranVerse: 'وَرَحْمَتِى وَسِعَتْ كُلَّ شَىْءٍ',
    verseTranslation: 'And My mercy encompasses all things.',
    verseReference: 'Surah Al-A’raf (7:156)',
    story: 'The story of the Prophet Muhammad (PBUH) being sent as a mercy to mankind.',
  },
  {
    word: 'Jannah',
    arabicWord: 'جنة',
    translation: 'Paradise',
    explanation: 'The eternal place of reward and bliss for the righteous in the Hereafter.',
    quranVerse: 'إِنَّ ٱلَّذِينَ يُؤْمِنُونَ وَيَعْمَلُونَ ٱلصَّٰلِحَٰتِ كَانَتْ لَهُمْ جَنَّٰتُ ٱلنَّعِيمِ',
    verseTranslation: 'Indeed, those who believe and do righteous deeds - for them are the Gardens of Pleasure.',
    verseReference: 'Surah Al-Furqan (25:15)',
    story: 'The story of the Prophet Muhammad (PBUH) describing the beauty of Jannah and its rewards.',
  },
  {
    word: 'Khalifah',
    arabicWord: 'خليفة',
    translation: 'Successor',
    explanation: 'A steward or representative of Allah on Earth.',
    quranVerse: 'إِنِّي جَاعِلٌۭ فِى ٱلۡأَرۡضِ خَلِيفَةًۭ',
    verseTranslation: 'Indeed, I am going to make upon the earth a vicegerent.',
    verseReference: 'Surah Al-Baqarah (2:30)',
    story: 'The story of how Allah appointed Prophet Adam (PBUH) as the first Khalifah on Earth.',
  },
  {
    word: 'Mawla',
    arabicWord: 'مولى',
    translation: 'Protector',
    explanation: 'A guardian or protector, referring to Allah as the ultimate Mawla of the believers.',
    quranVerse: 'إِنَّمَا وَلِيُّكُمُ ٱللَّهُ وَرَسُولُهُ',
    verseTranslation: 'Your ally is none but Allah and His Messenger.',
    verseReference: 'Surah Al-Ma’idah (5:55)',
    story: 'The story of how Prophet Muhammad (PBUH) declared Ali ibn Abi Talib as his Mawla.',
  },
  {
    word: 'Bismillah',
    arabicWord: 'بِسْمِ ٱللَّهِ',
    translation: 'In the name of Allah',
    explanation: 'A phrase that signifies seeking Allah\'s blessings before undertaking any task.',
    quranVerse: 'بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ',
    verseTranslation: 'In the name of Allah, the Most Gracious, the Most Merciful.',
    verseReference: 'Surah Al-Fatiha (1:1)',
    story: 'The story of how the phrase "Bismillah" is used to invoke Allah\'s blessings in every endeavor.',
  },
  {
    word: 'Sadaqah',
    arabicWord: 'صدقة',
    translation: 'Charity',
    explanation: 'Voluntary acts of charity given to help others for the sake of Allah.',
    quranVerse: 'وَمَا تُنفِقُوا۟ مِنْ خَيْرٍ فَلِأَنفُسِكُمْ',
    verseTranslation: 'Whatever you spend of good is for yourselves.',
    verseReference: 'Surah Al-Baqarah (2:272)',
    story: 'The story of how the Prophet Muhammad (PBUH) emphasized the importance of giving Sadaqah, even in small amounts.',
  },
  {
    word: 'Rizq',
    arabicWord: 'رزق',
    translation: 'Sustenance',
    explanation: 'Provision, sustenance, or livelihood provided by Allah to His creation.',
    quranVerse: 'وَفَجَّرْنَا ٱلۡأَرۡضَ عُيُونًۭا فَٱلۡتَقَى ٱلْمَآءُ عَلَىٰٰٓٓٓ أَمۡرٍۢ قَدَرٍۢ',
    verseTranslation: 'And We caused the earth to gush forth with springs, so the waters met for a matter already predestined.',
    verseReference: 'Surah Al-Qamar (54:12)',
    story: 'The story of how Allah provides Rizq to every creature on Earth, with the story of the manna and quails sent to the Israelites.',
  },
  {
    word: 'Tawakkul',
    arabicWord: 'توكلت',
    translation: 'Reliance',
    explanation: 'Trusting in Allah’s plan and relying on Him after taking necessary actions.',
    quranVerse: 'وَتَوَكَّلْتُ عَلَىٰ اللَّـهِ رَبِّي وَرَبِّكُمْ',
    verseTranslation: 'And I have placed my trust in Allah, my Lord and your Lord.',
    verseReference: 'Surah Ibrahim (14:11)',
    story: 'The story of how the Prophet Ibrahim (PBUH) demonstrated ultimate Tawakkul when he was cast into the fire by his people.',
  },
  {
    word: 'Zakat',
    arabicWord: 'زكاة',
    translation: 'Almsgiving',
    explanation: 'The obligatory giving of a portion of one’s wealth to the poor and needy.',
    quranVerse: 'وَأَقِيمُوا۟ ٱلصَّلَوٰةَ وَءَاتُوا۟ ٱلزَّكَٰةَ',
    verseTranslation: 'And establish prayer and give zakah.',
    verseReference: 'Surah Al-Baqarah (2:43)',
    story: 'The story of how the Prophet Muhammad (PBUH) established the institution of Zakat for the welfare of the community.',
  },
  {
    word: 'Makkah',
    arabicWord: 'مكة',
    translation: 'Makkah',
    explanation: 'The holiest city in Islam, home to the Kaaba and the center of the Hajj pilgrimage.',
    quranVerse: 'إِنَّمَا ٱلۡمُؤۡمِنُونَ ٱلَّذِينَ إِذَا ذُكِرَ ٱللَّهُ وَجِلَتْ قُلُوبُهُمْ',
    verseTranslation: 'The believers are those who, when Allah is mentioned, their hearts are filled with awe.',
    verseReference: 'Surah Al-Anfal (8:2)',
    story: 'The story of how Makkah became the spiritual center of Islam, with the Kaaba as the focal point.',
  },
  {
    word: 'Masjid',
    arabicWord: 'مسجد',
    translation: 'Mosque',
    explanation: 'A place of worship where Muslims gather for prayer and reflection.',
    quranVerse: 'إِنَّمَآ أَمَٰنَٰتُ ٱللَّهِ وَرَسُولِهِۦ',
    verseTranslation: 'Indeed, the mosques are for Allah.',
    verseReference: 'Surah Al-Jinn (72:18)',
    story: 'The story of how the first Masjid was established by Prophet Muhammad (PBUH) in Madinah.',
  },
  {
    word: 'Al-Amin',
    arabicWord: 'الأمين',
    translation: 'The Trustworthy',
    explanation: 'A title given to Prophet Muhammad (PBUH) due to his honesty and integrity.',
    quranVerse: 'يَا أَيُّهَا ٱلَّذِينَ ءَامَنُوا۟ لَا تَخُونُوا۟ ٱللَّهَ وَرَسُولَهُۥ',
    verseTranslation: 'O you who have believed, do not betray Allah and His Messenger.',
    verseReference: 'Surah Al-Anfal (8:27)',
    story: 'The story of how the Prophet Muhammad (PBUH) was called "Al-Amin" even before his prophethood.',
  },
  {
    word: 'Arafah',
    arabicWord: 'عرفات',
    translation: 'Arafah',
    explanation: 'The site near Makkah where pilgrims gather on the 9th of Dhu al-Hijjah during Hajj.',
    quranVerse: 'وَإِذَآ أَذَّنَا فِىٰ جَمَعَةٍۢ فَسَعُوا۟ فِىٰ لَعِبٍۢ وَلَهْوٍۢ',
    verseTranslation: 'When the call is made for the prayer on the Day of Arafah, rush to perform the Hajj rites.',
    verseReference: 'Surah Al-Baqarah (2:198)',
    story: 'The story of how the Prophet Muhammad (PBUH) delivered his Farewell Sermon at Arafah.',
  },
  {
    word: 'Jabir',
    arabicWord: 'جابر',
    translation: 'He who Consoles',
    explanation: 'A companion of the Prophet Muhammad (PBUH), known for his kindness and support.',
    quranVerse: 'يَا أَيُّهَا الَّذِينَ آمَنُوا۟ لَا تَسْتَهْزِئُوا۟',
    verseTranslation: 'O you who have believed, do not mock one another.',
    verseReference: 'Surah Al-Hujurat (49:11)',
    story: 'The story of how Jabir ibn Abdullah was a constant support to Prophet Muhammad (PBUH) during battles.',
  },
  {
    word: 'Al-Huda',
    arabicWord: 'الهدى',
    translation: 'Guidance',
    explanation: 'Divine guidance provided by Allah to lead humanity on the straight path.',
    quranVerse: 'وَمَآ أَنتَ بِهَادِ ٱلۡعُمَىٰٰٓٓ',
    verseTranslation: 'And you are not one to guide the blind.',
    verseReference: 'Surah Al-Fath (48:27)',
    story: 'The story of how Allah’s guidance was provided to the Prophet Muhammad (PBUH) to lead his followers.',
  },
  {
    word: 'Kawthar',
    arabicWord: 'كوثر',
    translation: 'Abundance',
    explanation: 'A river in Paradise given to Prophet Muhammad (PBUH) as a reward for his patience.',
    quranVerse: 'إِنَّآ أَعْطَيْنَٰكَ ٱلۡكَوْثَرَ',
    verseTranslation: 'Indeed, We have granted you, [O Muhammad], al-Kawthar.',
    verseReference: 'Surah Al-Kawthar (108:1)',
    story: 'The story of how Prophet Muhammad (PBUH) was gifted al-Kawthar, a symbol of Allah’s blessings.',
  },
  {
    word: 'Badr',
    arabicWord: 'بدر',
    translation: 'Badr',
    explanation: 'The name of the battle where the Muslims achieved a decisive victory.',
    quranVerse: 'إِنَّ فِىٰ ذَٰلِكَ لَآيَٰتٍۢ لِّلۡمُؤْمِنِينَ',
    verseTranslation: 'Indeed, in that is a sign for the believers.',
    verseReference: 'Surah Al-Imran (3:123)',
    story: 'The story of how the Battle of Badr marked the turning point for the Muslim community.',
  },
  {
    word: 'Wahy',
    arabicWord: 'وحي',
    translation: 'Revelation',
    explanation: 'Divine communication from Allah to His messengers.',
    quranVerse: 'إِنَّمَآ أَنتَ مُنذِرٌۭ وَلِكُلِّ قَوْمٍۢ هَادٍۢ',
    verseTranslation: 'You are but a warner, and for every people is a guide.',
    verseReference: 'Surah Ar-Ra’d (13:7)',
    story: 'The story of how the Prophet Muhammad (PBUH) received Wahy over 23 years, guiding the Muslim community.',
  },
  {
    word: 'Nabi',
    arabicWord: 'نبي',
    translation: 'Prophet',
    explanation: 'A messenger chosen by Allah to convey His message to the people.',
    quranVerse: 'وَمَآ أَنتَ بِمُؤْمِنٍۢ بَأَنَّكُمۡ أَتَحِبُّونَ ٱلْفِتْنَةَ',
    verseTranslation: 'And you are not among those who believe in that which you do not know.',
    verseReference: 'Surah Ash-Shura (42:51)',
    story: 'The story of how Allah sent many Prophets like Ibrahim, Musa, and Isa (peace be upon them) to guide humanity.',
  },
  {
    word: 'Tawbah',
    arabicWord: 'توبة',
    translation: 'Repentance',
    explanation: 'Turning back to Allah and seeking forgiveness for one’s sins.',
    quranVerse: 'وَمَن يَفۡعَلْ فَإِنَّهُۥ فِيٰ قُدُورِ ٱللَّهِ',
    verseTranslation: 'And whoever does [so] then indeed, he has repented to Allah.',
    verseReference: 'Surah Al-Tahrim (66:8)',
    story: 'The story of how people who sincerely repent are forgiven by Allah, as was the case for Prophet Yunus (PBUH).',
  },
  {
    word: 'Dhikr',
    arabicWord: 'ذكر',
    translation: 'Remembrance',
    explanation: 'The practice of remembering Allah through recitation and reflection.',
    quranVerse: 'فَذْكُرُونِىٓ أَذْكُرْكُمْ',
    verseTranslation: 'So remember Me; I will remember you.',
    verseReference: 'Surah Al-Baqarah (2:152)',
    story: 'The story of how the Prophet Muhammad (PBUH) emphasized the power of Dhikr in bringing peace to the heart.',
  },
  {
    word: 'Hidayah',
    arabicWord: 'هدى',
    translation: 'Guidance',
    explanation: 'The path of righteousness that Allah has set for humanity.',
    quranVerse: 'إِنَّكَ لَا تَهۡدِى مَنٓ أَحْبَبْتَ',
    verseTranslation: 'Indeed, you do not guide whom you like.',
    verseReference: 'Surah Al-Qasas (28:56)',
    story: 'The story of how Allah guides whomever He wills, as seen in the guidance of Umar ibn al-Khattab (RA).',
  },
  {
    word: 'Nifaq',
    arabicWord: 'نفاق',
    translation: 'Hypocrisy',
    explanation: 'The act of concealing disbelief while outwardly showing belief.',
    quranVerse: 'إِنَّ ٱلۡمُنَٰفِقِينَ فِى ٱلدَّرْكِ ٱلۡأَسْفَلِ مِنَ ٱلنَّارِ',
    verseTranslation: 'Indeed, the hypocrites will be in the lowest depths of the Fire.',
    verseReference: 'Surah An-Nisa (4:145)',
    story: 'The story of how the Prophet Muhammad (PBUH) dealt with the issue of Nifaq among the community.',
  },
  {
    word: 'Ahlul-Bayt',
    arabicWord: 'أهل البيت',
    translation: 'People of the House',
    explanation: 'The family of the Prophet Muhammad (PBUH), revered for their piety and closeness to him.',
    quranVerse: 'إِنَّمَا يُرِيدُ ٱللَّهُ لِيُذۡهِبَ عَنكُمُ ٱلرِّجۡسَ أَهْلَ ٱلْبَيْتِ',
    verseTranslation: 'Indeed, Allah only intends to remove the impurity from you, O People of the House.',
    verseReference: 'Surah Al-Ahzab (33:33)',
    story: 'The story of how the family of the Prophet (PBUH) was honored by Allah and their role in early Islamic history.',
  },
  {
    word: 'Mujahid',
    arabicWord: 'مجاهد',
    translation: 'Warrior in the Path of Allah',
    explanation: 'A person who struggles or fights in the cause of Allah.',
    quranVerse: 'إِنَّ ٱلَّذِينَ ءَامَنُوا۟ وَجَٰهِدُوا۟ فِى سَبِيلِ ٱللَّهِ',
    verseTranslation: 'Indeed, those who have believed and fought in the cause of Allah.',
    verseReference: 'Surah At-Tawbah (9:20)',
    story: 'The story of how the companions of the Prophet Muhammad (PBUH) fought in the battles of Badr and Uhud.',
  },
  {
    word: 'Halal',
    arabicWord: 'حلال',
    translation: 'Permissible',
    explanation: 'That which is lawful or permitted according to Islamic law.',
    quranVerse: 'يَٰٓاَيُّهَا الَّذِينَ ءَامَنُوا۟ إِنَّمَا ٱلۡمُشْرِكُونَ نَجَسٌۭ',
    verseTranslation: 'O you who have believed, indeed, the polytheists are impure.',
    verseReference: 'Surah At-Tawbah (9:28)',
    story: 'The story of how the concept of Halal and Haram was established for the welfare of the Muslim community.',
  },
  {
    word: 'Haram',
    arabicWord: 'حرام',
    translation: 'Forbidden',
    explanation: 'That which is prohibited by Allah in Islam.',
    quranVerse: 'وَلَا تَقْتُلُوا۟ ٱلنَّفۡسَ ٱلَّتِى حَرَّمَ ٱللَّهُ إِلَّا بِٱلۡحَقِّ',
    verseTranslation: 'And do not kill the soul which Allah has forbidden, except by right.',
    verseReference: 'Surah Al-Isra (17:33)',
    story: 'The story of how unlawful actions like drinking alcohol and theft were condemned in the Quran.',
  },
    {
      word: "Allah",
      arabicWord: "الله",
      translation: "God",
      explanation: "The one true, supreme deity in Islam, the Creator of the heavens and the earth.",
      quranVerse: "اللَّهُ لَا إِلَٰهِ إِلَّا هُوَ",
      verseTranslation: "Allah! There is no deity except Him.",
      verseReference: "Surah Al-Baqarah (2:255)",
      story: "Allah's attributes are central to Islamic belief, emphasizing His oneness (Tawhid) and sovereignty over all creation."
    },
    {
      word: "Muhammad",
      arabicWord: "مُحَمَّد",
      translation: "Muhammad",
      explanation: "The final prophet and messenger of Islam, through whom the Quran was revealed.",
      quranVerse: "مَّا كَانَ مُحَمَّدٌ أَبَا أَحَدٍ مِّن رِّجَالِكُمْ وَلَٰكِن رَّسُولَ اللَّهِ وَخَاتَمَ النَّبِيِّينَ",
      verseTranslation: "Muhammad is not the father of any of your men, but he is the Messenger of Allah and the Seal of the Prophets.",
      verseReference: "Surah Al-Ahzab (33:40)",
      story: "Muhammad (PBUH) received the final revelation from Allah, guiding humanity in all aspects of life."
    },
    {
      word: "Jibril",
      arabicWord: "جِبْرِيل",
      translation: "Gabriel",
      explanation: "The archangel who delivered Allah's revelations to the prophets, including the Quran to Prophet Muhammad.",
      quranVerse: "نَزَلَ بِهِ الرُّوحُ الْأَمِينُ",
      verseTranslation: "The trustworthy spirit [Gabriel] has descended with it.",
      verseReference: "Surah Ash-Shu'ara (26:193)",
      story: "Jibril (Gabriel) is the angel who brought Allah's messages to various prophets, including the Quran to Prophet Muhammad."
    },
    {
      word: "Imam",
      arabicWord: "إِمَام",
      translation: "Leader",
      explanation: "The leader of the Muslim community, particularly in prayer or as a religious guide.",
      quranVerse: "وَجَعَلْنَٰكُمْ أُمَّةً وَسَطًا لِّتَكُونُوا۟ شُهَدَآءَ عَلَىٰ النَّاسِ وَيَكُونَ الرَّسُولُ عَلَيْكُمْ شَهِيدًا",
      verseTranslation: "And thus we have made you a just community that you will be witnesses over the people and the Messenger will be a witness over you.",
      verseReference: "Surah Al-Baqarah (2:143)",
      story: "The role of the Imam is to guide the community in matters of worship and to lead prayers, with an example set by Prophet Muhammad (PBUH)."
    },
    {
      word: "Rasul",
      arabicWord: "رَسُول",
      translation: "Messenger",
      explanation: "A messenger sent by Allah with divine revelations to guide humanity.",
      quranVerse: "وَرَسُولٌۭ مِّنَ ٱللَّهِ يَأْتِيكُمُ بِمَا يُبَيِّنُ لَكُمْ",
      verseTranslation: "And a Messenger from Allah will come to you who will clarify what is clear to you.",
      verseReference: "Surah An-Nisa (4:165)",
      story: "Prophets and messengers like Muhammad (PBUH) are sent by Allah to deliver His message to humanity. Muhammad is the final **Rasul** in Islam."
    },
    {
      word: "Quran",
      arabicWord: "قُرْآن",
      translation: "Quran",
      explanation: "The holy book of Islam, revealed to Prophet Muhammad (PBUH) by Allah, containing guidance for all aspects of life.",
      quranVerse: "إِنَّ هَٰذَا الْقُرْآنَ يَحْدِي لِلَّتِي هِيَ أَقْوَمُ",
      verseTranslation: "Indeed, this Quran guides to that which is most suitable.",
      verseReference: "Surah Al-Isra (17:9)",
      story: "The Quran is the literal word of Allah, revealed over 23 years, and serves as a guidance for all Muslims in their religious, social, and personal lives."
    }
    
  
];

function QuranicWords() {
  return (
    <div className="quranic-words-container">
      {wordsData.map((wordItem, index) => (
        <div className="quranic-word-card" key={index}>
          <h2 className="word-title">{wordItem.word} - <span className="arabic-word">{wordItem.arabicWord}</span></h2>
          <p className="word-translation">{wordItem.translation}</p>
          <p className="word-explanation">{wordItem.explanation}</p>
          <p className="quran-verse">"{wordItem.quranVerse}"</p>
          <p className="verse-translation">{wordItem.verseTranslation}</p>
          <p className="verse-reference">{wordItem.verseReference}</p>
          <p className="word-story">{wordItem.story}</p>
        </div>
      ))}
    </div>
  );
}

export default QuranicWords;
