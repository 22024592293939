import React from 'react';
import './IslamicBabyNames.css'; // Ensure this CSS file is created

const babyNames = [
    { name: 'Aaliyah', urduName: 'عالیہ', meaning: 'Exalted, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Aamir', urduName: 'عامر', meaning: 'Prosperous, full of life', gender: 'Male', origin: 'Arabic' },
    { name: 'Amina', urduName: 'امینہ', meaning: 'Trustworthy, faithful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ali', urduName: 'علی', meaning: 'Exalted, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Amir', urduName: 'امیر', meaning: 'Prince, leader', gender: 'Male', origin: 'Arabic' },
    { name: 'Bilal', urduName: 'بلال', meaning: 'Moisture; the name of the Prophet’s muezzin', gender: 'Male', origin: 'Arabic' },
    { name: 'Dalia', urduName: 'دالیہ', meaning: 'Gentle, slender', gender: 'Female', origin: 'Arabic' },
    { name: 'Fatima', urduName: 'فاطمہ', meaning: 'Captivating, one who abstains', gender: 'Female', origin: 'Arabic' },
    { name: 'Farah', urduName: 'فرح', meaning: 'Joy, happiness', gender: 'Female', origin: 'Arabic' },
    { name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
    { name: 'Hadi', urduName: 'ہادی', meaning: 'Guide to the right path', gender: 'Male', origin: 'Arabic' },
    { name: 'Hana', urduName: 'ہنہ', meaning: 'Happiness, bliss', gender: 'Female', origin: 'Arabic' },
    { name: 'Ibrahim', urduName: 'ابراہم', meaning: 'Father of many', gender: 'Male', origin: 'Hebrew' },
    { name: 'Jasmine', urduName: 'یاسمین', meaning: 'A fragrant flower', gender: 'Female', origin: 'Persian' },
    { name: 'Khadija', urduName: 'خدیجہ', meaning: 'Early baby, daughter of the Prophet', gender: 'Female', origin: 'Arabic' },
    { name: 'Khalid', urduName: 'خالد', meaning: 'Eternal, everlasting', gender: 'Male', origin: 'Arabic' },
    { name: 'Laila', urduName: 'لیلی', meaning: 'Night; dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Layla', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Lina', urduName: 'لینا', meaning: 'Tender, delicate', gender: 'Female', origin: 'Arabic' },
    { name: 'Mansoor', urduName: 'منصور', meaning: 'Victorious', gender: 'Male', origin: 'Arabic' },
    { name: 'Mariam', urduName: 'مریم', meaning: 'Beloved, a variation of Mary', gender: 'Female', origin: 'Arabic' },
    { name: 'Najma', urduName: 'نجما', meaning: 'Star', gender: 'Female', origin: 'Arabic' },
    { name: 'Nadia', urduName: 'نادیا', meaning: 'Hope, tender', gender: 'Female', origin: 'Slavic' },
    { name: 'Noor', urduName: 'نور', meaning: 'Light, radiance', gender: 'Unisex', origin: 'Arabic' },
    { name: 'Omar', urduName: 'عمر', meaning: 'Flourishing, long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Rami', urduName: 'رامی', meaning: 'Archer', gender: 'Male', origin: 'Arabic' },
    { name: 'Rania', urduName: 'رانیا', meaning: 'Queen, gazing', gender: 'Female', origin: 'Arabic' },
    { name: 'Reem', urduName: 'ریم', meaning: 'White antelope', gender: 'Female', origin: 'Arabic' },
    { name: 'Salim', urduName: 'سلیم', meaning: 'Safe, sound', gender: 'Male', origin: 'Arabic' },
    { name: 'Samira', urduName: 'سمیرا', meaning: 'Companion in evening talk', gender: 'Female', origin: 'Arabic' },
    { name: 'Sami', urduName: 'سامی', meaning: 'Elevated, sublime', gender: 'Male', origin: 'Arabic' },
    { name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Sofia', urduName: 'سوفیہ', meaning: 'Wisdom', gender: 'Female', origin: 'Greek' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, he who knocks at the door', gender: 'Male', origin: 'Arabic' },
    { name: 'Yara', urduName: 'یارا', meaning: 'Small butterfly; to flow', gender: 'Female', origin: 'Arabic' },
    { name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
    { name: 'Zain', urduName: 'زین', meaning: 'Beauty, grace', gender: 'Male', origin: 'Arabic' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Father’s precious jewel', gender: 'Female', origin: 'Arabic' },
    { name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Asad', urduName: 'اسد', meaning: 'Lion', gender: 'Male', origin: 'Arabic' },
    { name: 'Amna', urduName: 'امنا', meaning: 'Peace, safety', gender: 'Female', origin: 'Arabic' },
    { name: 'Zahra', urduName: 'زہرا', meaning: 'Flower, bright', gender: 'Female', origin: 'Arabic' },
    { name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases', gender: 'Male', origin: 'Hebrew' },
    { name: 'Rehana', urduName: 'ریحانہ', meaning: 'Fragrance, sweet basil', gender: 'Female', origin: 'Arabic' },
    { name: 'Nour', urduName: 'نور', meaning: 'Light, illumination', gender: 'Unisex', origin: 'Arabic' },
    { name: 'Anisa', urduName: 'انیسہ', meaning: 'Friendly, companion', gender: 'Female', origin: 'Arabic' },
    { name: 'Samia', urduName: 'سما', meaning: 'Elevated, exalted', gender: 'Female', origin: 'Arabic' },
    { name: 'Shirin', urduName: 'شیرین', meaning: 'Sweet, pleasant', gender: 'Female', origin: 'Persian' },
    { name: 'Usama', urduName: 'اسامہ', meaning: 'Lion', gender: 'Male', origin: 'Arabic' },
    { name: 'Areeba', urduName: 'اریبہ', meaning: 'Intelligent, wise', gender: 'Female', origin: 'Arabic' },
    { name: 'Humaira', urduName: 'ہمیرہ', meaning: 'Red, rosy', gender: 'Female', origin: 'Arabic' },
    { name: 'Amal', urduName: 'عمل', meaning: 'Hope, aspiration', gender: 'Female', origin: 'Arabic' },
    { name: 'Ayman', urduName: 'ایمن', meaning: 'Blessed, fortunate', gender: 'Male', origin: 'Arabic' },
    { name: 'Basim', urduName: 'باسم', meaning: 'Smiling', gender: 'Male', origin: 'Arabic' },
    { name: 'Daniya', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
    { name: 'Hiba', urduName: 'ہبہ', meaning: 'Gift', gender: 'Female', origin: 'Arabic' },
    { name: 'Ismail', urduName: 'اسماعیل', meaning: 'God will hear', gender: 'Male', origin: 'Hebrew' },
    { name: 'Kareem', urduName: 'کریم', meaning: 'Generous, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Maya', urduName: 'مایا', meaning: 'Illusion, dream', gender: 'Female', origin: 'Persian' },
    { name: 'Naseem', urduName: 'نسیم', meaning: 'Breeze, soft wind', gender: 'Female', origin: 'Arabic' },
    { name: 'Rania', urduName: 'رانیا', meaning: 'Gazing, looking at', gender: 'Female', origin: 'Arabic' },
    { name: 'Sadiq', urduName: 'صادق', meaning: 'Truthful, honest', gender: 'Male', origin: 'Arabic' },
    { name: 'Sukaina', urduName: 'سکینہ', meaning: 'Calm, peaceful', gender: 'Female', origin: 'Arabic' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star', gender: 'Male', origin: 'Arabic' },
    { name: 'Zaina', urduName: 'زینت', meaning: 'Beauty, adornment', gender: 'Female', origin: 'Arabic' },
    { name: 'Zayd', urduName: 'زید', meaning: 'Growth, abundance', gender: 'Male', origin: 'Arabic' },
    { name: 'Fatima', urduName: 'فاطمہ', meaning: 'One who abstains', gender: 'Female', origin: 'Arabic' },
    { name: 'Bilqis', urduName: 'بلقیس', meaning: 'Queen of Sheba', gender: 'Female', origin: 'Arabic' },
    { name: 'Anwar', urduName: 'انور', meaning: 'Radiant, luminous', gender: 'Male', origin: 'Arabic' },
    { name: 'Rashid', urduName: 'رشید', meaning: 'Rightly guided', gender: 'Male', origin: 'Arabic' },
    { name: 'Aalia', urduName: 'عالیہ', meaning: 'Exalted, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Adnan', urduName: 'عدنان', meaning: 'Settler, one who is well-established', gender: 'Male', origin: 'Arabic' },
    { name: 'Afnan', urduName: 'افنان', meaning: 'Branches, flourishing', gender: 'Female', origin: 'Arabic' },
    { name: 'Alia', urduName: 'عالیہ', meaning: 'Noble, exalted', gender: 'Female', origin: 'Arabic' },
    { name: 'Arwa', urduName: 'اروا', meaning: 'Mountain goat; fresh', gender: 'Female', origin: 'Arabic' },
    { name: 'Danish', urduName: 'دانش', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Persian' },
    { name: 'Farhan', urduName: 'فرحان', meaning: 'Happy, joyful', gender: 'Male', origin: 'Arabic' },
    { name: 'Fatimah', urduName: 'فاطمہ', meaning: 'One who abstains', gender: 'Female', origin: 'Arabic' },
    { name: 'Junaid', urduName: 'جنید', meaning: 'Soldier', gender: 'Male', origin: 'Arabic' },
    { name: 'Lamia', urduName: 'لامیہ', meaning: 'Shining, radiant', gender: 'Female', origin: 'Arabic' },
    { name: 'Malik', urduName: 'مالک', meaning: 'King, master', gender: 'Male', origin: 'Arabic' },
    { name: 'Mansoorah', urduName: 'منصورہ', meaning: 'Victorious', gender: 'Female', origin: 'Arabic' },
    { name: 'Muneeb', urduName: 'منیب', meaning: 'One who turns to God', gender: 'Male', origin: 'Arabic' },
    { name: 'Nida', urduName: 'ندا', meaning: 'Call, voice', gender: 'Female', origin: 'Arabic' },
    { name: 'Rehan', urduName: 'ریحان', meaning: 'Sweet basil; a fragrant herb', gender: 'Male', origin: 'Arabic' },
    { name: 'Sajid', urduName: 'ساجد', meaning: 'One who prostrates', gender: 'Male', origin: 'Arabic' },
    { name: 'Suhana', urduName: 'سہانا', meaning: 'Beautiful, pleasant', gender: 'Female', origin: 'Arabic' },
    { name: 'Talha', urduName: 'طلحہ', meaning: 'A tree; a companion of the Prophet', gender: 'Male', origin: 'Arabic' },
    { name: 'Zahira', urduName: 'زاہرہ', meaning: 'Radiant, blossoming', gender: 'Female', origin: 'Arabic' },
    { name: 'Zaroon', urduName: 'زروان', meaning: 'Visitor, guest', gender: 'Male', origin: 'Arabic' },
    { name: 'Yumna', urduName: 'یمنہ', meaning: 'Lucky, blessed', gender: 'Female', origin: 'Arabic' },
    { name: 'Zoya', urduName: 'زویہ', meaning: 'Alive, prosperous', gender: 'Female', origin: 'Arabic' },
    { name: 'Aariz', urduName: 'عارض', meaning: 'Respectable, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Aayla', urduName: 'آئلہ', meaning: 'Moonlight', gender: 'Female', origin: 'Arabic' },
    { name: 'Azaan', urduName: 'اذان', meaning: 'Call to prayer', gender: 'Male', origin: 'Arabic' },
    { name: 'Bilqis', urduName: 'بلقیس', meaning: 'Queen of Sheba', gender: 'Female', origin: 'Arabic' },
    { name: 'Dania', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
    { name: 'Emran', urduName: 'امران', meaning: 'Prosperity', gender: 'Male', origin: 'Arabic' },
    { name: 'Fariha', urduName: 'فرحہ', meaning: 'Happy, joyful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ghazal', urduName: 'غزل', meaning: 'Poetry, song', gender: 'Female', origin: 'Persian' },
    { name: 'Hiba', urduName: 'ہبہ', meaning: 'Gift, present', gender: 'Female', origin: 'Arabic' },
    { name: 'Imran', urduName: 'عمران', meaning: 'Prosperity', gender: 'Male', origin: 'Arabic' },
    { name: 'Jamal', urduName: 'جمال', meaning: 'Beauty', gender: 'Male', origin: 'Arabic' },
    { name: 'Kainat', urduName: 'کائنات', meaning: 'Universe', gender: 'Female', origin: 'Arabic' },
    { name: 'Mahira', urduName: 'ماہرہ', meaning: 'Skilled, expert', gender: 'Female', origin: 'Arabic' },
    { name: 'Naima', urduName: 'نعیمہ', meaning: 'Comfort, tranquility', gender: 'Female', origin: 'Arabic' },
    { name: 'Owais', urduName: 'اوئیس', meaning: 'A companion of the Prophet', gender: 'Male', origin: 'Arabic' },
    { name: 'Parveen', urduName: 'پروین', meaning: 'The Pleiades; a group of stars', gender: 'Female', origin: 'Persian' },
    { name: 'Rayyan', urduName: 'ریان', meaning: 'Gates of Heaven', gender: 'Male', origin: 'Arabic' },
    { name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Tania', urduName: 'تانیہ', meaning: 'Fairy queen', gender: 'Female', origin: 'Persian' },
    { name: 'Vania', urduName: 'وینیا', meaning: 'God is gracious', gender: 'Female', origin: 'Hebrew' },
    { name: 'Wasiq', urduName: 'واسط', meaning: 'Confident', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasira', urduName: 'یاسرہ', meaning: 'Easy-going', gender: 'Female', origin: 'Arabic' },
    { name: 'Zakir', urduName: 'ذاکر', meaning: 'One who remembers Allah', gender: 'Male', origin: 'Arabic' },
    { name: 'Zainul', urduName: 'زین العابدین', meaning: 'Adornment of the worshippers', gender: 'Male', origin: 'Arabic' },
    { name: 'Zayna', urduName: 'زینت', meaning: 'Beauty, adornment', gender: 'Female', origin: 'Arabic' },
    { name: 'Adnan', urduName: 'عدنان', meaning: 'Settler, one who is a refuge', gender: 'Male', origin: 'Arabic' },
    { name: 'Aira', urduName: 'ایرا', meaning: 'Noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Asiya', urduName: 'آسیہ', meaning: 'One who tends to the weak', gender: 'Female', origin: 'Arabic' },
    { name: 'Danish', urduName: 'دانش', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Persian' },
    { name: 'Dua', urduName: 'دعا', meaning: 'Prayer', gender: 'Female', origin: 'Arabic' },
    { name: 'Ehsan', urduName: 'احسان', meaning: 'Benevolence, charity', gender: 'Male', origin: 'Arabic' },
    { name: 'Hanan', urduName: 'حنان', meaning: 'Compassion, kindness', gender: 'Female', origin: 'Arabic' },
    { name: 'Hisham', urduName: 'ہشام', meaning: 'Generous', gender: 'Male', origin: 'Arabic' },
    { name: 'Inaya', urduName: 'عنایہ', meaning: 'Care, concern', gender: 'Female', origin: 'Arabic' },
    { name: 'Khalilah', urduName: 'خلیلہ', meaning: 'Friend, companion', gender: 'Female', origin: 'Arabic' },
    { name: 'Mufeed', urduName: 'مفید', meaning: 'Beneficial', gender: 'Male', origin: 'Arabic' },
    { name: 'Nida', urduName: 'ندا', meaning: 'Call, voice', gender: 'Female', origin: 'Arabic' },
    { name: 'Rayya', urduName: 'ریحا', meaning: 'Flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Raza', urduName: 'رضا', meaning: 'Contentment', gender: 'Male', origin: 'Arabic' },
    { name: 'Sabah', urduName: 'صباح', meaning: 'Morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Salma', urduName: 'سلمیٰ', meaning: 'Peaceful, safe', gender: 'Female', origin: 'Arabic' },
    { name: 'Sultan', urduName: 'سلطان', meaning: 'King, ruler', gender: 'Male', origin: 'Arabic' },
    { name: 'Tahira', urduName: 'طاہرہ', meaning: 'Pure, virtuous', gender: 'Female', origin: 'Arabic' },
    { name: 'Yasir', urduName: 'یاسر', meaning: 'Wealthy, prosperous', gender: 'Male', origin: 'Arabic' },
    { name: 'Zaki', urduName: 'ذکی', meaning: 'Intelligent, pure', gender: 'Male', origin: 'Arabic' },
    { name: 'Zareen', urduName: 'زرین', meaning: 'Golden, precious', gender: 'Female', origin: 'Persian' },
    { name: 'Zunaira', urduName: 'زونیرا', meaning: 'A flower, a type of plant', gender: 'Female', origin: 'Arabic' },
    { name: 'Afnan', urduName: 'افنان', meaning: 'Branches, fronds', gender: 'Male', origin: 'Arabic' },
    { name: 'Alayna', urduName: 'الینا', meaning: 'Sky, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Bilqis', urduName: 'بلقیس', meaning: 'Queen of Sheba', gender: 'Female', origin: 'Arabic' },
    { name: 'Daud', urduName: 'داود', meaning: 'Beloved', gender: 'Male', origin: 'Hebrew' },
    { name: 'Fariha', urduName: 'فرح', meaning: 'Happy, joyful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ghazal', urduName: 'غزل', meaning: 'Poetic love, deer', gender: 'Female', origin: 'Arabic' },
    { name: 'Hamza', urduName: 'حمزہ', meaning: 'Lion, strong', gender: 'Male', origin: 'Arabic' },
    { name: 'Hooriya', urduName: 'حوریا', meaning: 'A fairy, a beautiful woman', gender: 'Female', origin: 'Arabic' },
    { name: 'Ibtisam', urduName: 'ابتسام', meaning: 'Smile', gender: 'Female', origin: 'Arabic' },
    { name: 'Jibril', urduName: 'جبریل', meaning: 'The angel Gabriel', gender: 'Male', origin: 'Arabic' },
    { name: 'Kareem', urduName: 'کریم', meaning: 'Generous, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Malik', urduName: 'مالک', meaning: 'King, ruler', gender: 'Male', origin: 'Arabic' },
    { name: 'Nafisa', urduName: 'نفیسہ', meaning: 'Precious', gender: 'Female', origin: 'Arabic' },
    { name: 'Qadir', urduName: 'قادِر', meaning: 'Powerful', gender: 'Male', origin: 'Arabic' },
    { name: 'Rashid', urduName: 'راشد', meaning: 'Rightly guided', gender: 'Male', origin: 'Arabic' },
    { name: 'Sadiya', urduName: 'سادیہ', meaning: 'Lucky, fortunate', gender: 'Female', origin: 'Arabic' },
    { name: 'Shahzad', urduName: 'شہزاد', meaning: 'Prince', gender: 'Male', origin: 'Persian' },
    { name: 'Tamara', urduName: 'تمارا', meaning: 'Date palm', gender: 'Female', origin: 'Arabic' },
    { name: 'Ummar', urduName: 'عمار', meaning: 'Long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasira', urduName: 'یاسرہ', meaning: 'Easy, prosperous', gender: 'Female', origin: 'Arabic' },
    { name: 'Zabir', urduName: 'ذابر', meaning: 'One who is aware', gender: 'Male', origin: 'Arabic' },
    { name: 'Zinah', urduName: 'زینت', meaning: 'Beauty, adornment', gender: 'Female', origin: 'Arabic' }
];

// Function to remove duplicates based on name
const removeDuplicates = (array) => {
    const uniqueNames = new Set();
    return array.filter((item) => {
        const isDuplicate = uniqueNames.has(item.name);
        uniqueNames.add(item.name);
        return !isDuplicate;
    });
};

// Sort the baby names in alphabetical order
const uniqueBabyNames = removeDuplicates(babyNames);
const sortedBabyNames = uniqueBabyNames.sort((a, b) => a.name.localeCompare(b.name));

const IslamicBabyNames = () => {
  return (
    <div className="baby-names-container">
      <h2 className="baby-names-title">اسلامی بچوں کے نام</h2> {/* Urdu Title */}
      <table className="baby-names-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Urdu Name</th>
            <th>Meaning</th>
            <th>Gender</th>
            <th>Origin</th>
          </tr>
        </thead>
        <tbody>
          {sortedBabyNames.map((baby, index) => (
            <tr key={index}>
              <td>{baby.name}</td>
              <td>{baby.urduName}</td>
              <td>{baby.meaning}</td>
              <td>{baby.gender}</td>
              <td>{baby.origin}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IslamicBabyNames;
