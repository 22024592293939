import React from 'react';
import './SearchBar.css'; // Import CSS for SearchBar

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  return (
    <input
      type="text"
      placeholder="Search posts..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
      className="search-input" // Apply CSS class for styling
    />
  );
};

export default SearchBar;
