import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase'; // Firestore import
import { collection, addDoc } from 'firebase/firestore';
import './PostForm.css'; // Import CSS for PostForm

const PostForm = ({ setPosts }) => {
  const [content, setContent] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid); // Set user ID on mount if the user is logged in
    }

    // Optional: Clear userId when the user logs out
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId('');
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content) return; // Prevent submission of empty content
    if (!userId) {
      alert("You must be logged in to post!"); // Alert if no user is logged in
      return;
    }

    try {
      // Create a new post object
      const newPost = {
        content,
        author: userId, // Use actual user ID here
        likes: 0,
        likedBy: [], // Initialize likedBy as an empty array
        createdAt: new Date() // Set the current date and time as createdAt
      };

      // Log the new post data
      console.log("Adding post: ", newPost);

      // Add a new post to Firestore
      const docRef = await addDoc(collection(db, 'posts'), newPost);

      // Update posts state by adding the new post
      setPosts((prevPosts) => [
        {
          id: docRef.id,
          ...newPost // Spread the new post to include createdAt and other fields
        },
        ...prevPosts // Add new post to the top of the list
      ]);

      // Clear the input after submission
      setContent('');
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <form className="post-form" onSubmit={handleSubmit}>
      <h2>Share Your Thoughts</h2>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Type your content here..."
        required
      />
      <button type="submit" disabled={!userId}> {/* Disable button if no user is logged in */}
        Post
      </button>
    </form>
  );
};

export default PostForm;
