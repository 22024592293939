// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';  // Import getAuth
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'; 

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCBQnk6Agbd-xXY6G8CkrC3YzJEb6Q0Gqs",
  authDomain: "the-quran-club.firebaseapp.com",
  projectId: "the-quran-club",
  storageBucket: "the-quran-club.firebasestorage.app",
  messagingSenderId: "291396283996",
  appId: "1:291396283996:web:2b54270d8af0664aa30099",
  measurementId: "G-Z4MV98K572"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Auth
const db = getFirestore(app); // Initialize Firestore
const analytics = getAnalytics(app);

// Export the auth and db instances
export { auth, db };
