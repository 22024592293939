import React, { useState, useEffect } from 'react';
import './App.css';
import SignUp from './SignUp';
import Login from './Login';
import PostForm from './PostForm';
import Posts from './Posts';
import Search from './Search';
import AdminDashboard from './AdminDashboard';
import { db, auth } from './firebase';
import { collection, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import UrduCalendar from './UrduCalendar';
import IslamicBabyNames from './IslamicBabyNames';
import QuranicWords from './QuranicWords'; // Import QuranicWords component
import './QuranicWords.css'; // Import the CSS for QuranicWords
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Updated import for Routes

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserId(user.email);
        setUserEmail(user.email);
        setIsAdmin(user.email === 'fayyadqadrikhan@gmail.com');
        setShowLogin(false);
        setShowSignUp(false);
      } else {
        setIsLoggedIn(false);
        setUserEmail('');
        setIsAdmin(false);
        setUserId('');
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
      const postsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postsData);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  const handleLike = async (postId) => {
    const postRef = doc(db, 'posts', postId);
    const post = posts.find((p) => p.id === postId);

    if (post) {
      const updatedLikes = post.likes + 1;

      // Update likes in Firestore
      await updateDoc(postRef, { likes: updatedLikes });

      // Update local posts state without rearranging posts
      const updatedPosts = posts.map((p) =>
        p.id === postId ? { ...p, likes: updatedLikes } : p
      );

      setPosts(updatedPosts); // Update state without sorting
    }
  };

  return (
    <Router>
      <div className="App">
        <main>
          <h1 className="App-header">Quranic Sharing Platform</h1>
          <UrduCalendar />
          {showLogin ? (
            <Login setIsLoggedIn={setIsLoggedIn} />
          ) : showSignUp ? (
            <SignUp setIsLoggedIn={setIsLoggedIn} />
          ) : (
            <Routes>
              <Route path="/home" element={
                <>
                  {isLoggedIn && !isAdmin && <PostForm setPosts={setPosts} />}
                  <Search onSearch={setSearchTerm} />
                  <Posts
                    posts={posts}
                    searchTerm={searchTerm}
                    userId={userEmail}
                    onLike={handleLike}
                    isLoggedIn={isLoggedIn}
                  />
                  {isAdmin && (
                    <div>
                      <button onClick={() => setIsAdmin(false)}>Switch to User View</button>
                      <AdminDashboard setPosts={setPosts} />
                    </div>
                  )}
                </>
              } />

              <Route path="/quranic-words" element={<QuranicWords />} />
              <Route path="/islamic-baby-names" element={<IslamicBabyNames />} />
              {/* You can add other routes for different pages here */}
            </Routes>
          )}
        </main>

        {/* Left Navigation for Desktop */}
        <nav className="left-nav">
          <Link to="/home">Home</Link>
          <Link to="/islamic-baby-names">Islamic Baby Names</Link>
          <Link to="/quranic-words">Quranic Words</Link>
          <a
            href="https://israelpalestine.liveuamap.com/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Israel-Palestine Live Updates
          </a>
          {!isLoggedIn && (
            <>
              <button onClick={() => { setShowLogin(true); setShowSignUp(false); }}>Login</button>
              <button onClick={() => { setShowSignUp(true); setShowLogin(false); }}>Sign Up</button>
            </>
          )}
          {isLoggedIn && (
            <button onClick={handleLogout}>Logout</button>
          )}
        </nav>

        {/* Bottom Navigation for Mobile */}
        <nav className="bottom-nav">
          <Link to="/home">Home</Link>
          <Link to="/islamic-baby-names">Islamic Baby Names</Link>
          <Link to="/quranic-words">Quranic Words</Link>
          <a
            href="https://israelpalestine.liveuamap.com/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Israel-Palestine Live Updates
          </a>
          {!isLoggedIn && (
            <>
              <button onClick={() => { setShowLogin(true); setShowSignUp(false); }}>Login</button>
              <button onClick={() => { setShowSignUp(true); setShowLogin(false); }}>Sign Up</button>
            </>
          )}
          {isLoggedIn && (
            <button onClick={handleLogout}>Logout</button>
          )}
        </nav>
      </div>
    </Router>
  );
}

export default App;
