import React, { useEffect, useState } from 'react';
import { db } from './firebase'; // Firestore import
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import SearchBar from './SearchBar'; // Import the SearchBar component
import './AdminDashboard.css'; // Import CSS for Admin Dashboard

const AdminDashboard = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postsData);
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);

  const handleDelete = async (postId) => {
    const postRef = doc(db, 'posts', postId);
    try {
      await deleteDoc(postRef);
      console.log('Post deleted:', postId); // Log the deleted post ID
  
      // Remove the deleted post from state
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error); // Log any errors
    }
  };

  const filteredPosts = posts.filter((post) => {
    const postContent = post.content ? post.content.toLowerCase() : ''; // Check if content exists
    const postTitle = post.title ? post.title.toLowerCase() : ''; // Check if title exists
    return postContent.includes(searchTerm.toLowerCase()) || postTitle.includes(searchTerm.toLowerCase());
  });

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} /> {/* Use SearchBar component */}
      {filteredPosts.length > 0 ? (
        filteredPosts.map((post) => (
          <div key={post.id} className="post-item">
            <h3>{post.title}</h3>
            <p>{post.content}</p>
            <p>Likes: {post.likes}</p>
            <button onClick={() => handleDelete(post.id)}>Delete</button>
          </div>
        ))
      ) : (
        <p>No posts found.</p> // Fallback if no posts match the search
      )}
    </div>
  );
};

export default AdminDashboard;
