// UrduCalendar.js
import React from 'react';
import moment from 'moment-hijri';
import './UrduCalendar.css'; // Style the calendar component

const UrduCalendar = () => {
  // Format the current date to Hijri in Urdu: Year, Month, Date
  const hijriYear = moment().format('iYYYY'); 
  const hijriMonth = moment().format('iMMMM');
  const hijriDate = moment().format('iD'); 

  return (
    <div className="urdu-calendar">
      <h2 className="urdu-calendar-title">آج کی تاریخ</h2>
      <div className="calendar-date-container">
        <span className="calendar-year">{hijriYear}</span>
        <span className="calendar-month">{hijriMonth}</span>
        <span className="calendar-date">{hijriDate}</span>
      </div>
    </div>
  );
};

export default UrduCalendar;
