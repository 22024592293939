import React, { useEffect, useState } from 'react';
import { db, auth } from './firebase'; // Import Firestore and Auth
import { collection, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import './Posts.css'; // Import CSS for Posts

const Posts = ({ searchTerm }) => {
  const [posts, setPosts] = useState([]);
  const [userEmail, setUserEmail] = useState('');

  // Fetch user email from authentication state
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserEmail(user.email); // Get user email
    }
  }, []);

  // Fetch posts from Firestore on component mount
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date() // Convert Firestore timestamp to JavaScript Date
      }));
      // Sort posts by createdAt in descending order
      postsData.sort((a, b) => b.createdAt - a.createdAt);
      setPosts(postsData);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  // Filter posts based on the search term
  const filteredPosts = posts.filter(post =>
    post.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to render content with clickable links, YouTube, and Instagram embedding
  const renderContentWithLinksAndVideo = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i;
    const instagramRegex = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel)\/([A-Za-z0-9_-]+)/i;

    const parts = content.split(urlRegex);

    return parts.map((part, index) => {
      const youtubeMatch = part.match(youtubeRegex);
      if (youtubeMatch) {
        const videoId = youtubeMatch[1];
        return (
          <iframe
            key={index}
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube Video"
          ></iframe>
        );
      }

      const instagramMatch = part.match(instagramRegex);
      if (instagramMatch) {
        return (
          <blockquote
            key={index}
            className="instagram-media"
            data-instgrm-permalink={part}
            data-instgrm-version="14"
            style={{ width: '100%' }}
          >
            <a href={part} target="_blank" rel="noopener noreferrer">
              View Instagram Content
            </a>
          </blockquote>
        );
      }

      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }

      return part;
    });
  };

  // Handle liking/unliking a post
  const handleLike = async (postId) => {
    const postRef = doc(db, 'posts', postId);
    const post = posts.find(p => p.id === postId);

    if (post) {
      const likedBy = post.likedBy || [];
      const hasLiked = likedBy.includes(userEmail);
      const updatedLikes = hasLiked ? post.likes - 1 : (post.likes || 0) + 1;
      const updatedLikedBy = hasLiked
        ? likedBy.filter(id => id !== userEmail)
        : [...likedBy, userEmail];

      await updateDoc(postRef, {
        likes: updatedLikes,
        likedBy: updatedLikedBy
      });

      // Update local state after liking/unliking a post
      setPosts(posts.map(p =>
        p.id === postId ? { ...p, likes: updatedLikes, likedBy: updatedLikedBy } : p
      ));
    }
  };

  // Load Instagram embed script and initialize embeds
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [posts]); // Re-run script when posts change to re-process Instagram embeds

  return (
    <div className="posts-container">
      <h2>Posts</h2>
      {filteredPosts.length > 0 ? (
        filteredPosts.map((post) => (
          <div key={post.id} className="post">
            <p>{renderContentWithLinksAndVideo(post.content)}</p>
            <p>Likes: {post.likes || 0}</p>
            {userEmail && ( // Show like/unlike button only if user is logged in
              <button onClick={() => handleLike(post.id)}>
                {post.likedBy && post.likedBy.includes(userEmail) ? 'Unlike' : 'Like'}
              </button>
            )}
          </div>
        ))
      ) : (
        <p>No posts found.</p>
      )}
    </div>
  );
};

export default Posts;
